import React, { useEffect, useRef } from 'react';
import { List, ListMode, ListItemCustom } from 'src/common/ui5dependencies';
import { useTranslation } from './eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { Icon, Text } from 'src/common/ui5dependencies';
import { Ui5CustomEvent } from 'src/common/ui5dependencies';
import { UserProfileState } from 'src/types';

const { getThemeId, getThemeList } = eurekaMgrs.AppearanceManager;
const formItemStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
};

type Props = {
  data: UserProfileState;
  theme: languages[];
  onDataPropChange: (value: string, propName: string) => void;
};
interface languages {
  displayLanguageCode: string;
  language: string;
}

const UserAppearance: React.FC<Props> = ({ onDataPropChange }) => {
  const [currentTheme, setCurrentTheme] = React.useState(getThemeId());
  return (
    <div style={formItemStyle}>
      <List
        id="theme-setting"
        // TODO:
        // value={getThemeId()}
        selectionMode={ListMode.Single}
        onSelectionChange={(
          evt: Ui5CustomEvent<
            HTMLElement,
            {
              selectedItems: any[];
              previouslySelectedItems: any[];
            }
          >,
        ) => {
          const themeId = evt.detail?.selectedItems[0]?.dataset.id;
          console.log(`Theme Id is ${themeId}`);
          setCurrentTheme(themeId);
          onDataPropChange(themeId, 'themeId');
        }}
        style={{ width: '100%', height: '100%' }}
        data-testid="select-theme-id"
      >
        {getThemeList().map((theme, i) => {
          return (
            <ListItemCustom
              key={theme.themeId + '-option'}
              // TODO:
              // value={theme.themeId}
              selected={getThemeId() === theme.themeId}
              style={{ width: '100%', height: '44px' }}
              tooltip={theme.displayName}
              data-id={theme.themeId}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '0.875rem' }}>
                  <div
                    style={{
                      ...theme.style,
                      width: '0.875rem',
                      height: '0.875rem',
                      borderRadius: '100%',
                      margin: 'auto',
                    }}
                  ></div>
                </div>
                <div
                  data-testid={'select-theme-id-' + i}
                  style={{
                    width: '404px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '44px',
                    // paddingRight: '5px',
                  }}
                >
                  <Text>{theme.displayName}</Text>
                </div>
                {currentTheme === theme.themeId && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '1rem',
                      height: '1rem',
                      borderRadius: '50%',
                      background: ThemingParameters.sapSelectedColor,
                      // margin: '0 10px',
                    }}
                  >
                    <Icon
                      name="accept"
                      style={{
                        width: '0.75rem',
                        height: '0.75rem',
                        color: ThemingParameters.sapContent_ForegroundColor,
                      }}
                    />
                  </div>
                )}
              </div>
            </ListItemCustom>
          );
        })}
      </List>
    </div>
  );
};

export default UserAppearance;
